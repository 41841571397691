import React from 'react';
import { Navigate } from 'react-router-dom';

const ZaClaneVsebina = () => {
  const accessGranted = sessionStorage.getItem('accessGranted') === 'true';

  if (!accessGranted) {
    return <Navigate to="/" replace />;
  }

  return (
    <div>
      <h1>Protected Content</h1>
      <p>Welcome to the members-only area!</p>
    </div>
  );
};

export default ZaClaneVsebina;