import React, { useEffect, useRef, useState } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "./Kontakt.css";
import caminoLogo from "../Logos/Camino.jpg";

const Kontakt = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [coordinates, setCoordinates] = useState(null);

  useEffect(() => {
    if (mapContainer.current && !map.current) {
      map.current = new maplibregl.Map({
        container: mapContainer.current,
        style: {
          version: 8,
          sources: {
            osm: {
              type: "raster",
              tiles: [
                "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
                "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png",
                "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png"
              ],
              tileSize: 256,
              attribution: '© OpenStreetMap contributors'
            }
          },
          layers: [
            {
              id: "osm",
              type: "raster",
              source: "osm"
            }
          ]
        },
        center: [15.189719, 46.2676312],
        zoom: 13,
        pitch: 60,
        bearing: -17.6,
      });

      // Create a custom marker element for better clickability
      const markerElement = document.createElement('div');
      markerElement.className = 'marker-icon';

      // Create the marker with the custom icon
      const marker = new maplibregl.Marker({
        element: markerElement,
        anchor: 'center'
      })
        .setLngLat([15.189719, 46.2676312])
        .addTo(map.current);

      // Handle click event to update marker and coordinates
      map.current.on("click", (e) => {
        const lng = e.lngLat.lng.toFixed(5);
        const lat = e.lngLat.lat.toFixed(5);
        setCoordinates(`Latitude: ${lat}, Longitude: ${lng}`);

        marker.setLngLat([lng, lat]).addTo(map.current);
        marker.setPopup(
          new maplibregl.Popup({ offset: 25 }).setHTML(`
            <div style="text-align: center;">
              <h3 style="margin: 0; color: #17458f;">Clicked Location</h3>
              <img src="${caminoLogo}" alt="Clicked Location" style="width: 150px; height: auto; margin: 5px 0; border-radius: 8px;">
              <p style="margin: 5px 0;">Latitude: ${lat}, Longitude: ${lng}</p>
              <a href="https://www.google.com/maps/search/?api=1&query=${lat},${lng}" 
                target="_blank" 
                style="text-decoration: none; color: #f7a81b;">
                Open in Google Maps
              </a>
            </div>
          `)
        ).addTo(map.current);
      });
    }
  }, []);

  return (
    <div className="kontakt-container">
      <div className="kontakt-left">
        <div ref={mapContainer} className="kontakt-map"></div>
      </div>
      <div className="kontakt-right">
        <h3 style={{color:"#f7a81b"}}>Rotary Club - District 1912</h3>
        <div className="kontakt-info-text">
          <center><p>Informacije o klubu :</p></center>
          <center>
            <img src={caminoLogo} alt="Restavracija Camino" className="kontakt-logo" />
          </center>
          <p>• E-naslov: info@rotary-zalec.si</p>
          <p>• Ustanovitev: 19. 4. 2005</p>
          <p>• TRR: SI56 0510 08015110 167</p>
          <p>• Srečanja: Restavracija Camino, Mala Pirešica 21, 3301 Petrovče</p>
          <p>• Srečanja vsak ponedeljek ob 20:00h</p>
          {coordinates && <p className="coordinates">{coordinates}</p>}
        </div>
      </div>
    </div>
  );
};

export default Kontakt;