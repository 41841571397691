import React from "react";
import "./CastniClani.css";

const CastniClani = () => {
  const users = [
    { name: "Franc Pangerl ⚘", alt: "Častni član Rotary Club Žalec", image: "/Slike/CastniClani/pangerl.png" },
    { name: "Matjaž Pavčič ⚘", alt: "Častni član Rotary Club Žalec", image: "/Slike/CastniClani/matjaz.png" },
    { name: "Boštjan Marovt ⚘", alt: "Častni član Rotary Club Žalec", image: "/Slike/CastniClani/marovt.png" },
  ];

  return (
    <div className="castniclanicelkontejner">
      <div className="castniclanikontejner">
        <h4 className="castniclaniuserlisttitle">ČASTNI ČLANI</h4>
        <center>
        </center>
        <ul className="castniclaniuserlist" aria-label="Častni člani">
          {users.map((user, index) => (
            <li key={index} className="castniclaniuserlistitem">
              <img
                src={user.image}
                alt={user.alt}
                className="castniclaniuserlogo"
              />
              <span className="castniclaniusername" aria-label={`Ime: ${user.name}`}>
                {user.name}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CastniClani;