import React from "react";
import "./Footer.css";
import footerlogo from "../Logos/RotaryClubLogo.png";
import footerlogo2 from "../Logos/pandabit.jpg";
import facebookLogo from "../Logos/facebook.png";  
import youtubeLogo from "../Logos/youtube.png";    

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-section">
          <p>© 2025 Rotary Club. Vse pravice pridržane.</p>
          <a 
            href="https://rotaryslovenija.org/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="footer-link"
          >
            O Rotary Slovenija
          </a>
        </div>
        <div className="footer-section social-media">
          <p>Spremljajte nas:</p>
          <a 
            href="https://www.facebook.com/p/Rotary-Club-%C5%BDalec-100076549557937/?locale=sl_SI" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="social-link"
          >
            <img 
              src={facebookLogo} 
              alt="Facebook" 
              className="social-icon" 
            />
            Facebook
          </a>
          <a 
            href="https://www.youtube.com/@RotaryClub%C5%BDalec" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="social-link"
          >
            <img 
              src={youtubeLogo} 
              alt="YouTube" 
              className="social-icon" 
            />
            YouTube
          </a>
        </div>

        <div className="footer-section">
          <p>Powered by Pandabit</p>
          <img 
            src={footerlogo2} 
            alt="Pandabit Logo" 
            className="pandabit-logo" 
          /> 
        </div>
      </div>
    </footer>
  );
};

export default Footer;