import React, { useState, useEffect, useRef } from "react";
import "./Domov.css";
import Facebook from "../Logos/facebook.png";
import Instagram from "../Logos/instagram.png";
import Youtube from "../Logos/youtube.png";
import rotarygloballogo from "../Logos/RotaryClubLogo.png";

const Domov = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageProgress, setImageProgress] = useState(0); 
  const [isPlaying, setIsPlaying] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0); 
  const [totalDuration, setTotalDuration] = useState(0);
  const audioRef = useRef(null); 
  const [isSpinning, setIsSpinning] = useState(false);

  const [visible, setVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.75) {
          setVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); 
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = new Audio('/Slike/RotarySong.mp3');
    }
  
    audioRef.current.volume = 0.5;
    
    audioRef.current.onloadedmetadata = () => {
      setTotalDuration(Math.floor(audioRef.current.duration));
    };
  
    audioRef.current.ontimeupdate = () => {
      setElapsedTime(Math.floor(audioRef.current.currentTime));
    };
  
    audioRef.current.onended = () => {
      setIsSpinning(false);
      setIsPlaying(false);
    };
  
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsSpinning(false); 
    } else {
      audioRef.current.play();
      setIsSpinning(true); 
    }
    setIsPlaying(!isPlaying);
  };

  const images = [
    { src: "/Slike/Domov/rotary120.jpg"},
    { src: "/Slike/Domov/rotaryclani1.jpg"},
    { src: "/Slike/Domov/rotaryclani2.jpg"},
    { src: "/Slike/Domov/slika2.jpg"},
  ];

  const smallImages = [
    { src: "/Slike/Domov/rotary120.jpg", alt: "Rotary Club International 120.letnica, 5.April 2025, Hotel Rose Bled"},
    { src: "/Slike/Domov/rotaryclani1.jpg", alt: "Rotary Club Žalec"},
    { src: "/Slike/Domov/rotaryclani2.jpg", alt: "Rotary Club Žalec" },
    { src: "/Slike/Domov/slika2.jpg", alt: "Člani Rotary Club Žalec" },
  ];

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    setImageProgress(0); 
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
    setImageProgress(0); 
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setImageProgress((prevProgress) => {
        if (prevProgress >= 100) {
          nextImage();
          return 0; 
        }
        return prevProgress + 0.2; 
      });
    }, 16); 

    return () => clearInterval(interval);
  }, [currentIndex]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const preloadImage = (src) => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = src;
      document.head.appendChild(link);
      return link;
    };
  
    const links = [
      preloadImage(images[currentIndex].src),
      preloadImage(images[(currentIndex + 1) % images.length].src), 
    ];
  
    return () => {
      links.forEach((link) => document.head.removeChild(link));
    };
  }, [currentIndex]);

  return (
  <div>
    <div className="domovcontainer">
      <div className="domovimagesection">
        <div className="domovimagegallery">
          <div className="arrow left" onClick={prevImage}>
            &#10094;
          </div>
          <img
            src={images[currentIndex].src}
            alt={`image${currentIndex + 1}`}
            className="domovgalleryimage"
            loading="lazy"
          />
          <div className="arrow right" onClick={nextImage}>
            &#10095;
          </div>
          <div className="loading-bar-container">
    <div
      className="loading-bar"
      style={{ width: `${imageProgress}%` }}
    />
  </div>
        </div>
        <div className="domovsmallimagegallery">
          <div className="spin-container">
            {smallImages.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={image.alt}
                className="domovsmallgalleryimage"
                loading="lazy"
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </div>
        </div>
        <button onClick={togglePlayPause} className="domovhimnagumb">
          {isPlaying ? 'Ustavi' : 'Naša Himna'}
        </button>
         {formatTime(elapsedTime)} / {formatTime(totalDuration)}
         <img 
           src={rotarygloballogo} 
           className={`domovhimnas1 ${isSpinning ? "spin-active" : ""}`} 
          />
      </div>

      <div className="domovtextcontent">
      <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color:"#17458f" }}>Dobrodošli na spletni strani Rotary Club Žalec
      <img 
         src={rotarygloballogo} 
         style={{ height: '30px', marginLeft: '10px' }} 
         alt="Rotary Global Logo"
      />
      </h2>
        <p className="domovtextuvod">Rotary Club je srce skupnosti po vsem svetu, kjer se povezuje prijateljstvo in sočutje. Naš klub je del globalne mreže z več kot 1,2 milijona članov v več kot 35.000 klubih po svetu. 
           Delujemo od leta 2005 in sodelujemo pri ustvarjanju trajnih pozitivnih sprememb v skupnostih doma in po svetu.
        </p>
        <p>• Predsednik kluba 2025/2026 : Primož Temnik</p>
        <p>• Sekretar kluba 2025/2026 : Katja Bizaj</p>
        <h4>Najnovejši dogodki : 📅</h4>
        <div className="domovtextscrollcontainer">
        <p  style={{color:"red"}}> • 05.04.2025 - Praznovanje 120-letnice Rotary</p>
        <p>• 31.03.2025 - Predavanje Dr. Matej Bernard Kobav (Svetloba)</p>
        <p>• 24.02.2025 - Predavanje pivovarne Vizir, Žalec 🍺</p>
        <p>• 17.02.2025 - Potopisno predavanje, Žalec ⛵️</p>
        <p>• 14.11.2024 - Dobrodelni koncert, Vransko 🎶</p>
        <p>• 07.10.2024 - Obisk guvernerja, Žalec</p>
        <p>• 23.09.2024 - Dražba likovnih del, Grad Komenda 🎨</p>
        </div>
        <h4>Sledite nam na družabnih omrežjih :</h4>
        <div>        
         </div>
         
         <p className="social-media-item">
            <a href="https://www.facebook.com/p/Rotary-Club-%C5%BDalec-100076549557937/?locale=sl_SI" target="_blank" rel="noopener noreferrer">
              Facebook povezava :
             <img src={Facebook} alt="Rotary klub Žalec Facebook" className="social-icon" />
          </a>
        </p>   

         <p className="social-media-item">
            <a href="https://www.youtube.com/@RotaryClub%C5%BDalec" target="_blank" rel="noopener noreferrer">
              Youtube povezava :
             <img src={Youtube} alt="Rotary klub Žalec Youtube" className="social-icon" />
          </a>
        </p>       
      </div>
    </div>
    <div ref={ref} className={`domovspodenkontejner ${visible ? "visible" : "hidden"}`}>
    <div className="background-container">
      <img 
        src="/Slike/Domov/t2.jpg" 
        alt="Rotarijska vprašanja" 
        className="backgroundd-image" 
        loading="lazy"
      />
      <div className="background-overlay"></div> 
    </div>
    <br></br>
      <center><h1 className="domovgold-text">POSLANSTVO ROTARIJCA</h1></center>
      <center><h1 className="domovgold-text">- Vzpodbujati in krepiti ideale nudenja pomoči kot temelja dragocenih podvigov.</h1></center>
      <div className="domovdflex2-container">
        <div className="domovdflex2-text-container">
          <h1 className="domovgold-text">Rotary Club Žalec (since 2005) </h1>
          <p className="domovdflex2-text">1. Ali je prav?</p>
          <p className="domovdflex2-text">2. Ali je pošteno do vseh vpletenih?</p>
          <p className="domovdflex2-text">3. Ali bo krepilo prijateljstvo in pripravljenost na nesebično delovanje?</p>
          <p className="domovdflex2-text">4. Ali bo koristilo vsem udeleženim?</p>
        </div>
        <div className="domovdtext2-container">
          <img src="/Slike/Domov/rotaryslovenija.png" style={{ height: "150px" }} loading="lazy" />
        </div>
      </div>
      <div className="domovdflex2-container">
        <div className="domovdflex2-text-container">
          <h1 className="domovgold-text">Cilj Rotary Organizacije</h1>
          <p className="domovdflex2-text">• Gojiti prijateljstvo, ki krepi medsebojno pomoč.</p>
          <p className="domovdflex2-text">• Sprejemajo visoka etična načela v zasebnem in poklicnem življenju ter cenijo vsako dejavnost, ki je širši skupnosti v korist.</p>
        </div>
        <div className="domovdtext2-container">
          <img src="/Slike/Domov/k4.png" alt="Rotarijska vprašanja" style={{ height: "150px" }}   loading="lazy" />
        </div>
      </div>
      <div className="domovdflex2-container">
        <div className="domovdflex2-text-container">
          <h1 className="domovgold-text">Zakaj postati član Rotary Club ?</h1>
          <p className="domovdflex2-text">• Prijateljstvo</p>
          <p className="domovdflex2-text">• Poslovni Razvoj </p>
          <p className="domovdflex2-text">• Izboljšanje voditeljskih sposobnosti in osebne rasti </p>   
        </div>
        <div className="domovdtext2-container">
        <img src="/Slike/Domov/k3.png" alt="Rotarijska vprašanja" className="domovdflex2-image"   loading="lazy" />
        </div>
      </div>
      <div 
  className={`domovspodenefekt ${visible ? "visible" : ""}`} 
  ref={ref}
>
  <a href="/Clani">
    <img 
      src="/Slike/Domov/g1.png" 
      alt="Člani Rotary Club" 
      className="efekt-image"
        loading="lazy"
    />
  </a>
  <a href="/Onas/Podstrani/PostaniClan">
    <img 
      src="/Slike/Domov/g2.png" 
      alt="Rotary Kontakt" 
      className="efekt-image"
      loading="lazy"
    />
  </a>
</div>
<center>
<a href="/Dogodki">
    <img 
      src="/Slike/Domov/ga.png" 
      alt="Rotary Kontakt" 
      className="efekt-image"
      loading="lazy"
      style={{height:"40px", width:"40px", marginTop:"50px"}}
    />
  </a>
</center>
      <br></br> 
      <br></br>
    </div>
 
    </div>
  );
};

export default Domov;

