import React from "react";
import "./Onas.css";

const Onas = () => {

  return (
    <div className="onascelkontejner">
      <div className="onassimplelayoutcontainer">
      <div className="onastextcontainer">
      <h2>Kakšen je naš namen ?</h2>
        <p>
          Naš namen je pomagati pomoči potrebnim ljudem. 
          Klub se je ustanovil v letu 2005.
          Aktualni predsednik kluba v letu 2025 je Primož Temnik.
          Srečanja imamo vsak ponedeljek v restavraciji Camino, Mala Pirešica 21, 3301 Petrovče ob 20h.
        </p>
        <h2>Štiri zlata rotarijanska vprašanja :</h2>
        <p>Preizkus štirih vprašanj
           Predsednik Rotary International 1954/55, Herb Taylor, je uvedel tako imenovani preizkus štirih vprašanj. Sporočilo tega preizkusa morajo poznati vsi Rotarijci in mu slediti. </p>
        <p>Kadar Rotarijec o čem razmišlja, ali naj to naredi ali ne, se mora vselej vprašati:</p>
        <p>1. Ali je prav?</p>
        <p>2. Ali je pošteno do vseh vpletenih?</p>
        <p>3. Ali bo krepilo prijateljstvo in pripravljenost na nesebično delovanje?</p>
        <p>4. Ali bo koristilo vsem udeleženim?</p>
      </div>
    </div>
    </div>

  );
};

export default Onas;