import React, { useState, useEffect } from "react";
import "./Donacije.css";

const Donacije = () => {
  const donationAmount = 160000; 
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 500); 
  }, []);

  return (
    <div className="donacijecelkontejner">
      <div className={`donation-container ${isVisible ? "reveal" : ""}`}>
        <p>( 2005-2025 Rotary Club Žalec)</p>
        <p>Skupno število dobrodelnih dogodkov : 60 +</p>
        <h2 className="donation-title">DO SEDAJ SMO DONIRALI VEČ KOT :</h2>  
        <div className="donation-display">
          <span className="donation-symbol">€</span>
          <span className="donation-amount">{donationAmount.toLocaleString()}</span>
        </div>
      </div>
    </div>
  );
};

export default Donacije;