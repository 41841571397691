import React from "react";
import { Link } from "react-router-dom";
import "./Dogodki.css";

import rotarygloballogo from "../Logos/RotaryClubLogo.png";

const events = [
  {
    title: "Predavanje (Svetloba), Dr.Matej Bernard Kobav, 31.03.2025",
    image: "/Slike/Dogodki/PredavanjeSvetloba/slika1.jpg", 
    link: "/Dogodki/Podstrani/PredavanjeSvetloba/",
  },
  {
    title: "Degustacija piva pivovarne Vizir, 24.02.2025 - Žalec",
    image: "/Slike/Dogodki/DegustacijaPiva2025/slika1.jpg", 
    link: "/Dogodki/Podstrani/DegustacijaPiva2025/",
  },
  {
    title: "Potopisno predavanje, 17.02.2025 - Žalec",
    image: "/Slike/Dogodki/PotopisnoPredavanje2025/slika2.jpg", 
    link: "/Dogodki/Podstrani/PotopisnoPredavanje2025/",
  },
  {
    title: "Dobrodelni koncert Žarek Upanja, 14.11.2024 - Vransko",
    image: "/Slike/Dogodki/Koncert2024/slika1.jpg", 
    link: "/Dogodki/Podstrani/KoncertVransko2024/",
  },
  {
    title: "Dražba umetniških slik, 23.09.2024 - Grad Komenda",
    image: "/Slike/Dogodki/Drazba2024/slika1.jpg", 
    link: "/Dogodki/Podstrani/DrazbaSlik2024/",
  },
];

const Dogodki = () => {
  const handleEventClick = () => {
    window.scrollTo(0, 0); 
  };

  return (
    <div className="dogodkikontejnerd">
    <div className="dogodkieventgallery"><center> <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    PRETEKLI DOGODKI :
    <img 
      src={rotarygloballogo} 
      style={{ height: '30px', marginLeft: '10px' }} 
      alt="Rotary Global Logo"
    />
  </h2></center>   
      <p className="dogodkipodnaslov">
      Iskreno se zahvaljujemo dolgoletni nesebični pomoči policijskega orkestra, Občine Vransko in številnih glasbenikov ter ostalih po srcu dobrih ljudi, brez katerih ne bi dosegli to kar smo !
      </p>
      <div className="dogodkigallerygrid">
        {events.map((event, index) => (
          <div className="dogodkigalleryitem" key={index}>
            <Link to={event.link} className="dogodkiimagecontainer" onClick={handleEventClick}>
              <img
                src={process.env.PUBLIC_URL + event.image}
                alt={event.title}
                className="dogodkigalleryimage"
                loading="lazy"
              />
              <div className="dogodkioverlay">
                <p className="dogodkieventtitle">{event.title}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Dogodki;