import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./Donatorji.css";

const events = [
  {
    image: "/Slike/Donatorji/eles.png", alt:"ELES", 
  },
  {
    image: "/Slike/Donatorji/kea.png", alt:"Trgovina KEA", 
  },
  {
    image: "/Slike/Donatorji/kocevar.jpg", alt:"Ograje Kočevar", 
  },
  {
    image: "/Slike/Donatorji/ledx.png", alt:"LedX", 
  },
  {
    image: "/Slike/Donatorji/Mana.png", alt:"Mana", 
  },
  {
    image: "/Slike/Donatorji/minitec.jpg", alt:"Minitec", 
  },
  {
    image: "/Slike/Donatorji/petre.png", alt:"Petre šotori", 
  },
  {
    image: "/Slike/Donatorji/remontng.jpg", alt:"Remont NG", 
  },
  {
    image: "/Slike/Donatorji/zavarovalnicatriglav.jpg", alt:"Zavarovalnica Triglav", 
  },
  {
    image: "/Slike/Donatorji/celjskisejem.png", alt:"Celjski sejem", 
  },
  {
    image: "/Slike/Donatorji/bionic.png", alt:"bionic medical", 
  },
  {
    image: "/Slike/Donatorji/kminstalacije.png", alt:"KM Inštalacije", 
  },
  {
    image: "/Slike/Donatorji/vidim.jpg", alt:"Vidim očesni center", 
  },
  {
    image: "/Slike/Donatorji/Faraon.jpg", alt:"Hotel in Casino Faraon Celje", 
  },
  {
    image: "/Slike/Donatorji/123invest.png", alt:"123 Invest", 
  },
  {
    image: "/Slike/Donatorji/nepo.jpg", alt:"Nepo inženiring", 
  },
  {
    image: "/Slike/Donatorji/agentinepremicnine.png", alt:"Agenti Nepremičnine", 
  },
  {
    image: "/Slike/Donatorji/fmprojekti.png", alt:"FM Projekti", 
  },
  {
    image: "/Slike/Donatorji/iskra.jpg", alt:"Iskra esv d.d", 
  },
  {
    image: "/Slike/Donatorji/lekarnavransko.png", alt:"Lekarna Vransko", 
  },
  {
    image: "/Slike/Donatorji/qalita.png", alt:"Qualita trgovina in storitve", 
  },
  {
    image: "/Slike/Donatorji/rcom.png", alt:"Rcom mednarodna skupina", 
  },
  {
    image: "/Slike/Donatorji/rein.png", alt:"Rein skupina d.o.o.", 
  },
  {
    image: "/Slike/Donatorji/sanles.jpg", alt:"Sanles d.o.o.", 
  },
  {
    image: "/Slike/Donatorji/seba.png", alt:"Seba trgovina, proizvodnja in zaključna dela v gradbeništvu d.o.o. ", 
  },
  {
    image: "/Slike/Donatorji/speh.png", alt:"Slikopleskrstvo in fasaderstvo Špeh", 
  },
  {
    image: "/Slike/Donatorji/termosolar.jpg", alt:"Termosolar strojne inštalacije", 
  },
  {
    image: "/Slike/Donatorji/viba.png", alt:"Viba gradbena oprema in stroji", 
  },
];

const Donatorji = () => {

  const [visible, setVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.75) {
          setVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="donatorjikontejnert">
<div ref={ref} className={`donatorjieventgallery ${visible ? "visible" : "hidden"}`}>
<center>
  <h3 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    DONATORJI IN PODPORNIKI
  </h3>
</center>   
<p className="donatorjipodnaslov">
  Zahvaljujemo se donatorjem in podpornikom, ki znajo pomagati ljudem
</p>
<div className="donatorjigallerygrid">
  {events.map((event, index) => (
    <div className="donatorjigalleryitem" key={index}>
      <Link to={event.link} className="donatorjiimagecontainer">
        <img
          src={process.env.PUBLIC_URL + event.image}
          alt={event.title}
          className="donatorjigalleryimage"
          loading="lazy"
        />
        <div className="donatorjioverlay">
          <p className="donatorjieventtitle">{event.title}</p>
        </div>
      </Link>
    </div>
  ))}
</div>
<div className="donatorjisp1">
  <p>SPONZORJI V STORITVAH<br /><br />
  MVM D. O .O /
SAZAS /
	Radio Fantasy /
	Foto Rojnik /
	D. Pražnikar /
	VTV  /
	TV Celje /
	Petre d.o.o /
	(Oglaševanje v dvorani -Katja in Andrej) /
	OŠ Vransko Tabor / 
	Ozvočenje Rak /
	Občina Tabor  /
	Savinjske novice /</p>
</div>
</div>
</div>
  );
};

export default Donatorji;