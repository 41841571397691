import React from "react";
import "./PredsednikiKluba.css";
import rotarygloballogo from "../../Logos/RotaryClubLogo.png";

const PredsednikiKluba = () => {
  
  const users = [
    { date: "2024-2025", name: "Dejan Pehar", alt:"Dejan Pehar,Predsednik Rotary Club Žalec 2024/2025"},
    { date: "2023-2024", name: "Boštjan Pražnikar", alt:"Boštjan Pražnikar,Predsednik Rotary Club Žalec 2023/2024"  },
    { date: "2022-2023", name: "Blanka Nerad", alt:"Blanka Nerad,Predsednica Rotary Club Žalec 2022/2023"  },
    { date: "2021-2022", name: "Doc.dr. Miroslav Žaberl", alt:"Miroslav Žaberl,Predsednik Rotary Club Žalec 2021/2022"  },
    { date: "2020-2021", name: "Jožef Kočevar", alt:"Jožef Kočevar,Predsednik Rotary Club Žalec 2020/2021"  },
    { date: "2019-2020", name: "Darjo Pungartnik", alt:"Darjo Pungartnik,Predsednik Rotary Club Žalec 2019/2020"  },
    { date: "2018-2019", name: "Nemanja Lubarda", alt:"Nemanja Lubarda,Predsednik Rotary Club Žalec 2018/2019"  },
    { date: "2017-2018", name: "Magdalena Navodnik", alt:"Magdalena Navodnik,Predsednica Rotary Club Žalec 2017/2018"  },
    { date: "2016-2017", name: "Stanislav Veniger", alt:"Stanislav Veniger,Predsednik Rotary Club Žalec 2016/2017"  },
    { date: "2015-2016", name: "Igor Domjan", alt:"Igor Domjan,Predsednik Rotary Club Žalec 2015/2016"  },
    { date: "2014-2015", name: "Boštjan Marovt", alt:"Boštjan Marovt,Predsednik Rotary Club Žalec 2014/2015"  },
    { date: "2013-2014", name: "Anton Travner", alt:"Anton Travner,Predsednik Rotary Club Žalec 2013/2014"  },
    { date: "2012-2013", name: "Matjaž Pavčič", alt:"Matjaž Pavčič,Predsednik Rotary Club Žalec 2012/2013"  },
    { date: "2011-2012", name: "Jožko Gregorovič", alt:"Jožko Gregorovič,Predsednik Rotary Club Žalec 2011/2012"  },
    { date: "2010-2011", name: "Borut Sitar", alt:"Borut Sitar,Predsednik Rotary Club Žalec 2010/2011"  },
    { date: "2009-2010", name: "Franc Meža", alt:"Franc Meža,Predsednik Rotary Club Žalec 2009/2010"  },
    { date: "2008-2009", name: "Simon Svet", alt:"Simon Svet,Predsednik Rotary Club Žalec 2008/2009" },
    { date: "2007-2008", name: "Marjan Žohar", alt:"Marjan Žohar,Predsednik Rotary Club Žalec 2007/2008" },
    { date: "2006-2007", name: "Tatjana Žgank Meža",  alt:"Tatjana Žgank Meža,Predsednica Rotary Club Žalec 2006/2007" },
    { date: "2005-2006", name: "Zvone Petek", alt:"Zvone Petek,Predsednik Rotary Club Žalec 2005/2006" },
  ];

  return (
    <div className="predsednikicelkontejner">
    <div className="predsednikiscrollcontainer">
    <h3 className="predsednikiuserlisttitle">Zgodovina predsednikov Rotary Club Žalec  2005-2025 ⚘</h3>
    <ul className="predsednikiuserlist">
      {users.map((user, index) => (
        <li key={index} className="predsednikiuserlistitem">
          <span className="predsednikiuserdate">{user.date}</span>
          <span className="predsednikiusername">{user.name}</span> 
          <img src={rotarygloballogo} style={{height:"20px", marginLeft:"10px", marginBottom:"3px"}} />
        </li>
      ))}
    </ul>
  </div>
    </div>

  );
};

export default PredsednikiKluba;