import React, { useState } from "react";
import "./DogodkiPodstrani.css";

const images = [
  { src: "/Slike/Dogodki/DegustacijaPiva2025/slika1.jpg", text: "Slika 1: Direktorica pivovarne Vizir, Maja Imširović", alt: "Maja Imširović" },
  { src: "/Slike/Dogodki/DegustacijaPiva2025/slika2.jpg", text: "Slika 2: Izložba piv pivovarne Vizir", alt:"Pivovarna Vizir" },
  { src: "/Slike/Dogodki/DegustacijaPiva2025/slika4.jpg", text: "Slika 4: Predstavitev Rotary Club Žalec", alt: "Darjo Pungartnik" },
  { src: "/Slike/Dogodki/DegustacijaPiva2025/slika5.jpg", text: "Slika 5: Vodja dogodka Blanka Nerad", alt:"Blanka Nerad" },
  { src: "/Slike/Dogodki/DegustacijaPiva2025/slika6.jpg", text: "Slika 6: Zahvala Rotary Club Žalec", alt: "Predstavitev pivovarne" },
  { src: "/Slike/Dogodki/DegustacijaPiva2025/slika7.jpg", text: "Slika 7: Člana in gost", alt: "Gostje Rotary Club Žalec" },
  { src: "/Slike/Dogodki/DegustacijaPiva2025/slika8.jpg", text: "Slika 8: Zahvala za glasbeni nastop", alt: "Glasbeni nastop" },
  { src: "/Slike/Dogodki/DegustacijaPiva2025/slika9.jpg", text: "Slika 9: Člani Rotary Club Žalec", alt :"Člani Rotary Club Žalec" },
];

const DegustacijaPiva2025 = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedText, setSelectedText] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleImageClick = (image, text, index) => {
    setSelectedImage(image);
    setSelectedText(text);
    setCurrentIndex(index);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setSelectedText(null);
    setCurrentIndex(null);
  };

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[nextIndex].src);
    setSelectedText(images[nextIndex].text);
    setCurrentIndex(nextIndex);
  };

  const handlePrev = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[prevIndex].src);
    setSelectedText(images[prevIndex].text);
    setCurrentIndex(prevIndex);
  };

  return (
    <div className="dogodkipodstranigallerycontainer">
      <h1 className="dogodkipodstranigallerytitle">Degustacija piva pivovarne Vizir 24.02.2025</h1>
      <h3>( Galerija Slik )</h3>
      <div className="dogodkipodstranigallery">
        {images.map((image, index) => (
          <div className="dogodkipodstranigalleryitem" key={index}>
            <img
              src={image.src}
              alt={`Event ${index + 1}`}
              className="dogodkipodstranigalleryimage"
              loading="lazy"
              onClick={() => handleImageClick(image.src, image.text, index)}
            />
            <p className="dogodkipodstraniimagetext">{image.text}</p>
          </div>
        ))}
      </div>

      {selectedImage && (
        <div className="dogodkimodal" onClick={handleCloseModal}>
          <div className="dogodkimodalcontent" onClick={(e) => e.stopPropagation()}>
            <button className="dogodkiarrow-left" onClick={handlePrev}>
              &#8592;
            </button>
            <img
              src={selectedImage}
              alt="Full-size event"
              className="dogodkimodalimage"
            />
            <p className="dogodkimodaltext">{selectedText}</p>
            <button className="dogodkiclosebutton" onClick={handleCloseModal}>
              X
            </button>
            <button className="dogodkiarrow-right" onClick={handleNext}>
              &#8594;
            </button>
          </div>
        </div>
      )}
      <br></br>
            <p>SPLETNA STRAN PIVOVARNE VIZIR:</p>
                <a href="https://vizir.si/" target="_blank" rel="noopener noreferrer">
              <img src="/Slike/Dogodki/DegustacijaPiva2025/vizir.jpg" alt="Pivovarna Vizir" className="pivovarnavizirlink"/>
           </a>
    </div>
  );
};

export default DegustacijaPiva2025;