import React, { useState } from "react";
import "./DogodkiPodstrani.css";

const images = [
  { src: "/Slike/Dogodki/Koncert2024/slika2.jpg", text: "Slika 1: Pihalni orkester slovenske policije", alt:"Pihalni orkester slovenske Policije"},
  { src: "/Slike/Dogodki/Koncert2024/slika1.jpg", text: "Slika 2: Glasbena skupina Čuki", alt:"Glasbena skupina Čuki"},
  { src: "/Slike/Dogodki/Koncert2024/slika5.jpg", text: "Slika 3: Glasbenica Maja Založnik", alt:"Glasbenica Maja Založnik" },
  { src: "/Slike/Dogodki/Koncert2024/slika6.jpg", text: "Slika 4: Glasbenica Irena Vrčkovnik", alt:"Glasbenica Irena Vrčkovnik" },
  { src: "/Slike/Dogodki/Koncert2024/slika12.jpg", text: "Slika 5: Govor predsednika Rotary Club Žalec", alt:"Predsednik Rotary Club Žalec"},
  { src: "/Slike/Dogodki/Koncert2024/slika4.jpg", text: "Slika 6: Voditeljica dobrodelnega koncerta" },
  { src: "/Slike/Dogodki/Koncert2024/slika7.jpg", text: "Slika 7: Glasbenica Mia Guček", alt:"Glasbenica Mia Guček" },
  { src: "/Slike/Dogodki/Koncert2024/slika8.jpg", text: "Slika 8: Gosti dobrodelnega koncerta", alt:"Gosti dobrodelnega koncerta" },
  { src: "/Slike/Dogodki/Koncert2024/slika9.jpg", text: "Slika 9: Gosti dobrodelnega koncerta", alt:"Obiskovalci dobrodelnega koncerta" },
  { src: "/Slike/Dogodki/Koncert2024/slika14.jpg", text: "Slika 10: Dirigent policijskega orkestra", alt:"Dirigent policijskega orkestra" },
  { src: "/Slike/Dogodki/Koncert2024/slika3.jpg", text: "Slika 11: Panorama dogodka", alt:"Policijski orkester s glasbenico Majo Založnik" },
  { src: "/Slike/Dogodki/Koncert2024/slika13.jpg", text: "Slika 12: Glasbenik Jernej Tozon in pevki", alt:"Jernej Tozon in pevki" },
  { src: "/Slike/Dogodki/Koncert2024/slika15.jpg", text: "Slika 13: Glasbenik Jože Potrebuješ", alt:"Glasbenik Jože Potrebuješ" },
  { src: "/Slike/Dogodki/Koncert2024/slika10.jpg", text: "Slika 14: Skupna zbrana donacija dobrodelnega koncerta", alt:"Zbrane donacije dobrodelnega koncerta" },
];

const KoncertVransko2024 = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedText, setSelectedText] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleImageClick = (image, text, index) => {
    setSelectedImage(image);
    setSelectedText(text);
    setCurrentIndex(index);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setSelectedText(null);
    setCurrentIndex(null);
  };

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[nextIndex].src);
    setSelectedText(images[nextIndex].text);
    setCurrentIndex(nextIndex);
  };

  const handlePrev = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[prevIndex].src);
    setSelectedText(images[prevIndex].text);
    setCurrentIndex(prevIndex);
  };

  return (
    <div className="dogodkipodstranigallerycontainer">
      <h1 className="dogodkipodstranigallerytitle">Koncert Žarek Upanja 14.11.2024 Vransko </h1>
      <h3>( Galerija Slik )</h3>
      <div className="dogodkipodstranigallery">
        {images.map((image, index) => (
          <div className="dogodkipodstranigalleryitem" key={index}>
            <img
              src={image.src}
              alt={`Event ${index + 1}`}
              className="dogodkipodstranigalleryimage"
              loading="lazy"
              onClick={() => handleImageClick(image.src, image.text, index)}
            />
            <p className="dogodkipodstraniimagetext">{image.text}</p>
          </div>
        ))}
      </div>

      {selectedImage && (
        <div className="dogodkimodal" onClick={handleCloseModal}>
          <div className="dogodkimodalcontent" onClick={(e) => e.stopPropagation()}>
            <button className="dogodkiarrow-left" onClick={handlePrev}>
              &#8592;
            </button>
            <img
              src={selectedImage}
              alt="Full-size event"
              className="dogodkimodalimage"
            />
            <p className="dogodkimodaltext">{selectedText}</p>
            <button className="dogodkiclosebutton" onClick={handleCloseModal}>
              X
            </button>
            <button className="dogodkiarrow-right" onClick={handleNext}>
              &#8594;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default KoncertVransko2024;