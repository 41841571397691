import "./Clani.css";
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';

const members = [
  { img: "/Slike/Clani/magdalena2.jpg", name: "Magdalena Navodnik", clanstvo: "Ustanovni član : 2005",  misel:"- Let's be the change. Now!", alt:"Magdalena Navodnik" },
  { img: "/Slike/Clani/franc.jpg", name: "Franc Meža", clanstvo: "Ustanovni član : 2005", misel:"O", alt:"Franc Meža"  },
  { img: "/Slike/Clani/narcis.jpg", name: "Narcis Kantardžič", clanstvo: "Ustanovni član : 2005", misel:"O", alt:"Narcis Kantardžič" },
  { img: "/Slike/Clani/borut.jpg", name: "Borut Sitar", clanstvo: "Ustanovni član : 2005",  misel:"O", alt:"Borut Sitar"  },
  { img: "/Slike/Clani/jozko1.png", name: "Jožko Gregorovič", clanstvo: "Član od leta : 2006", misel:"- Gibalo človeškega razvoja je človeška lenoba.", alt:"Jožko Gregorovič" },
  { img: "/Slike/Clani/jozef1.jpg", name: "Jožef Kočevar", clanstvo: "Član od leta : 2016",  misel:"- Per Angusta Ad Augusta", alt:"Jožef Kočevar" },
  { img: "/Slike/Clani/nemanja.jpg", name: "Nemanja Lubarda", clanstvo: "Član od leta : V posodobitvi...",  misel:"O", alt:"Nemanja Lubarda" },
  { img: "/Slike/Clani/darjo.png", name: "Darjo Pungartnik", clanstvo: "Član od leta : 2007",  misel:"O", alt:"Darjo Pungartnik" },
  { img: "/Slike/Clani/katja3.png", name: "Dr. Katja Bizaj", clanstvo: "Član od leta : 2024",  misel:"Raj na zemlji ni kraj, ki ga iščemo, temveč izbira, ki jo ustvarjamo.", alt:"Katja Bizaj"  },
  { img: "/Slike/Clani/miroslav.jpg", name: "Doc.dr. Miroslav Žaberl", clanstvo: "Član od leta : V posodobitvi... ",  misel:"O", alt:"Miroslav Žaberl" },
  { img: "/Slike/Clani/primoz.jpg", name: "Primož Temnik", clanstvo: "Član od leta : V posodobitvi... ",  misel:"O", alt:"Primož Temnik" },
  { img: "/Slike/Clani/bostjan.jpg", name: "Boštjan Pražnikar", clanstvo: "Član od leta : V posodobitvi...",  misel:"O", alt:"Boštjan Pražnikar"  },
  { img: "/Slike/Clani/dejan.jpg", name: "Dejan Pehar", clanstvo: "Član od leta : V posodobitvi... ",  misel:"O", alt:"Dejan Pehar"  },
  { img: "/Slike/Clani/andrej.jpg", name: "Andrej Bizaj", clanstvo: "Član od leta : 2024",  misel:"O", alt:"Andrej Bizaj"  },
  { img: "/Slike/Clani/blanka.jpg", name: "Blanka Nerad", clanstvo: "Član od leta : V posodobitvi..." ,  misel:"O", alt:"Blanka Nerad" },
  { img: "/Slike/Clani/aljazv.jpg", name: "Aljaž Vrabič", clanstvo: "Član od leta : V posodobitvi... ",  misel:"O", alt:"Aljaž Vrabič"  },
  { img: "/Slike/Clani/janko.jpg", name: "Janko Parfant", clanstvo: "Član od leta : V posodobitvi... ",  misel:"O", alt:"Janko Parfant"  },
  { img: "/Slike/Clani/zan.jpg", name: "Žan Nerad", clanstvo: "Član od leta : 2024",  misel:"O", alt:"Žan Nerad"  },
  { img: "/Slike/Clani/aljaz.jpg", name: "Aljaž Nerad", clanstvo: "Član od leta : 2025",  misel:"O", alt:"Aljaž Nerad" },
  { img: "/Slike/Clani/oscar1.jpg", name: "Oscar Gregorovič", clanstvo: "Član od leta : 2025",  misel:"- Gibalo človeškega razvoja je medsebojna potrpežljivost.", alt:"Oscar Gregorovič" },
  { img: "/Slike/Clani/pangerl.png", name: "Franc Pangerl 🎗️", clanstvo: "Častni član", misel:"ČASTNI ČLAN", alt:"Franc Pangerl"  },
  { img: "/Slike/Clani/matjaz.png", name: "Matjaž Pavčič 🎗️", clanstvo: "Častni član",  misel:"ČASTNI ČLAN", alt:"Matjaž Pavčič"},
  { img: "/Slike/Clani/marovt.png", name: "Boštjan Marovt 🎗️", clanstvo: "Častni član",  misel:"ČASTNI ČLAN", alt:"Boštjan Marovt" },
];

const Clani = () => {

  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/ZaClane/ZaClaneVpis");
  };

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index); 
  };

  const [visible, setVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.75) {
          setVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); 
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
    <center>
      <h3 className="clanitext">Skupno število članov: ({members.length})</h3>
    </center>
    <div ref={ref} className={`clanigallerycontainer ${visible ? "visible" : "hidden"}`}>
      {members.map((member, index) => (
        <div
          className="clanigalleryitem"
          key={index}
          onClick={() => handleClick(index)} 
        >
          <div className="clanigalleryimage-container">
            <img
              src={member.img}
              alt={member.alt}
              className="clanigalleryimage"
              loading="lazy"
            />
            <div
              className={`misel-overlay ${activeIndex === index ? "visible" : "hidden"}`}
            >
              {member.misel}
            </div>
          </div>
          <div className="clanigallerytext">
            {activeIndex === index ? member.clanstvo : member.name}
          </div>
        </div>
      ))}
    </div>

    <center>
      <div className="candidate">    
      </div>
      <div className="vspomincrta"></div>
     <div className="vspomin">
       <h3 className="spomin-title">V SPOMIN</h3>
       <p className="spomin-description">Izjemen človek, s svojim trudom, predanostjo in dobrim srcem pustil neizbrisen pečat.</p>
         <img src="/Slike/Clani/stanislav.png" alt="Stanislav Veniger" className="in-memory" />
       <p className="image-caption">Stanislav Veniger, Ustanovni član : 2005</p>
     </div>
     <div className="vspomincrta"></div>
    </center>
  </div>
  );
};

export default Clani;




