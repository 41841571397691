import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ZaClaneVpis = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (code === 'rotary2005!') {
      sessionStorage.setItem('accessGranted', 'true'); 
      navigate('/ZaClane/ZaClaneVsebina'); 
    } else {
      setError('Invalid code. Please try again.');
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Vnesi Kodo</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter code"
          style={{ padding: '10px', fontSize: '16px' }}
        />
        <br />
        <button type="submit" style={{ marginTop: '20px', padding: '10px 20px' }}>
          Submit
        </button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default ZaClaneVpis;