import React, { useState } from "react";
import "./PostaniClan.css"; 
import rotarygloballogo from "../../Logos/RotaryClubLogo.png";

function PostaniClan() {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    message: ""
  });
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("Sending...");
  
    fetch("/contact.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        name: formData.name,
        surname: formData.surname,
        email: formData.email,
        message: formData.message,
      }),
    })
      .then((response) => response.text())
      .then((data) => {
        setStatus(data);
        
        if (data.includes("Sporočilo uspešno poslano")) {  
          setFormData({
            name: "",
            surname: "",
            email: "",
            message: "",
          }); 
        }
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setStatus("Failed to send email.");
      });
  };

  return (
    <div className="postaniclancelkontejner">
      <form className="contact-form" onSubmit={handleSubmit}>
        <center>
          <h2>KONTAKTNI OBRAZEC</h2>
          <p>Za dodatne informacije o klubu nam pišite...</p>
        </center>
        <input
          className="contact-form__input"
          type="text"
          name="name"
          placeholder="Ime..."
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          className="contact-form__input"
          type="text"
          name="surname"
          placeholder="Priimek..."
          value={formData.surname}
          onChange={handleChange}
          required
        />
        <input
          className="contact-form__input"
          type="email"
          name="email"
          placeholder="Email..."
          value={formData.email}
          onChange={handleChange}
          required
        />
        <textarea
          className="contact-form__textarea"
          name="message"
          placeholder="Vnesi sporočilo ..."
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
        <button className="contact-form__button" type="submit">Pošlji mail</button>
        <p className="contact-form__status">{status}</p>
      </form>
    </div>
  );
}

export default PostaniClan;