import React, {useEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Header/Header.js";
import Footer from "./Footer/Footer.js";
import Navigacija from "./Navigacija/Navigacija";
import Home from "./Domov/Domov.js";
import Onas from "./Onas/Onas";
import Clani from "./Clani/Clani";
import Dogodki from "./Dogodki/Dogodki";
import KoncertVransko2024 from "./Dogodki/Podstrani/KoncertVransko2024.js";
import Kontakt from "./Kontakt/Kontakt";
import PredsednikiKluba from "./Onas/Podstrani/PredsednikiKluba.js";
import CastniClani from "./Onas/Podstrani/CastniClani.js";
import PostaniClan from "./Onas/Podstrani/PostaniClan.js";
import Donacije from "./Onas/Podstrani/Donacije.js";
import DrazbaSlik2024 from "./Dogodki/Podstrani/DrazbaSlik2024.js";
import ZaClaneVpis from "./ZaClane/ZaClaneVpis.js";
import ZaClaneVsebina from "./ZaClane/ZaClaneVsebina.js";
import DegustacijaPiva2025 from "./Dogodki/Podstrani/DegustacijaPiva2025.js";
import PotopisnoPredavanje2025 from "./Dogodki/Podstrani/PotopisnoPredavanje2025.js";
import Donatorji from "./Donatorji/Donatorji.js";
import PredavanjeSvetloba from "./Dogodki/Podstrani/PredavanjeSvetloba.js";

const App = () => {

  return (
        <Router
        future={{
          v7_startTransition: true, 
          v7_relativeSplatPath: true, 
        }}
      >
        <Header />
        <Navigacija />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/onas" element={<Onas />} />
          <Route path="/Onas/Podstrani/PredsednikiKluba" element={<PredsednikiKluba />} />
          <Route path="/Onas/Podstrani/CastniClani" element={<CastniClani />} />
          <Route path="/Onas/Podstrani/PostaniClan" element={<PostaniClan />} />
          <Route path="/Onas/Podstrani/Donacije" element={<Donacije />} />

          <Route path="/clani" element={<Clani />} />
          <Route path="/dogodki" element={<Dogodki />} />
          <Route path="/donatorji" element={<Donatorji />} />
          <Route path="/kontakt" element={<Kontakt />} />     

          <Route path="/ZaClane/ZaClaneVpis" element={<ZaClaneVpis />} />
          <Route path="/ZaClane/ZaClaneVsebina" element={<ZaClaneVsebina />} />

          <Route path="/dogodki/podstrani/KoncertVransko2024" element={<KoncertVransko2024 />} />
          <Route path="/dogodki/podstrani/DrazbaSlik2024" element={<DrazbaSlik2024 />} />
          <Route path="/dogodki/podstrani/DegustacijaPiva2025" element={<DegustacijaPiva2025 />} />
          <Route path="/dogodki/podstrani/PotopisnoPredavanje2025" element={<PotopisnoPredavanje2025 />} />
          <Route path="/dogodki/podstrani/PredavanjeSvetloba" element={<PredavanjeSvetloba />} />
        </Routes>
        <Footer />
      </Router>
  );
};

export default App;