import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navigacija.css";

const isMobile = () => window.innerWidth <= 768;

const Navigacija = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const location = useLocation();
  const submenuRef = useRef(null);

  const submenuPaths = [
    "/Onas",
    "/Onas/PredsednikiKluba",
    "/Onas/CastniClani",
    "/Onas/Donacije",
    "/Onas/PostaniClan",
  ];

  useEffect(() => {
    setIsSubmenuOpen(submenuPaths.includes(location.pathname));
  }, [location.pathname]);

  const toggleSubmenu = (e) => {
    e.preventDefault();
    setIsSubmenuOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setIsSubmenuOpen(false);
  };

  const handleNavClick = () => {
    window.scrollTo(0, 0);
    closeMenu();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        submenuRef.current &&
        !submenuRef.current.contains(event.target) &&
        !event.target.closest(".submenu-trigger")
      ) {
        setIsSubmenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSubmenuHover = () => {
    if (!isMobile()) setIsSubmenuOpen(true);
  };

  const handleSubmenuLeave = () => {
    if (!isMobile()) setIsSubmenuOpen(false);
  };

  const handleSubmenuClick = (e) => {
    if (isMobile()) {
      e.preventDefault();
      setIsSubmenuOpen((prev) => !prev);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <div className="navbar">
      <div className="navbar-logo" translate="no">
        Service Above Self
      </div>

      <button className={`navbar-toggle ${isMenuOpen ? "active" : ""}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </button>

      <div className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
        <Link to="/" className="navbar-item" onClick={handleNavClick}>
          Domov
        </Link>

        <div
          className="navbar-item submenu-container"
          ref={submenuRef}
          onMouseEnter={handleSubmenuHover}
          onMouseLeave={handleSubmenuLeave}
          onClick={handleSubmenuClick}
        >
          <span className="submenu-trigger">
            <span className="navbar-item">O Nas ↓</span>
          </span>
          {isSubmenuOpen && (
            <div className="submenu">
              <Link to="/Onas" className="submenu-item" onClick={handleNavClick}>
                O Nas
              </Link>
              <Link
                to="/Onas/Podstrani/PredsednikiKluba"
                className="submenu-item"
                onClick={handleNavClick}
              >
                Predsedniki
              </Link>
              <Link
                to="/Onas/Podstrani/CastniClani"
                className="submenu-item"
                onClick={handleNavClick}
              >
                Častni člani
              </Link>
              <Link
                to="/Onas/Podstrani/Donacije"
                className="submenu-item"
                onClick={handleNavClick}
              >
                Donacije kluba
              </Link>
              <Link
                to="/Onas/Podstrani/PostaniClan"
                className="submenu-item"
                onClick={handleNavClick}
              >
                Kontaktirajte nas
              </Link>
            </div>
          )}
        </div>

        <Link to="/clani" className="navbar-item" onClick={handleNavClick}>
          Člani
        </Link>
        <Link to="/dogodki" className="navbar-item" onClick={handleNavClick}>
          Dogodki
        </Link>
        <Link to="/donatorji" className="navbar-item" onClick={handleNavClick}>
          Donatorji in podporniki
        </Link>
        <Link to="/kontakt" className="navbar-item" onClick={handleNavClick}>
          Kontakt
        </Link>
      </div>
    </div>
  );
};

export default Navigacija;