import React, { useState } from "react";
import "./DogodkiPodstrani.css";

const images = [
  { src: "/Slike/Dogodki/PredavanjeSvetloba/slika1.jpg", text: "Slika 1: Začetek predavanja (Svetloba)", alt: "Predavanje Svetloba, Dr.Matej Bernard Kobav" },
  { src: "/Slike/Dogodki/PredavanjeSvetloba/slika2.jpg", text: "Slika 2: Obiskovalci", alt:" Obiskovalci predavanja" },
  { src: "/Slike/Dogodki/PredavanjeSvetloba/slika3.jpg", text: "Slika 3: Predavatelj Dr.Matej Bernard Kobav ", alt:"Predavatelj Dr.Matej Bernard Kobav" },
  { src: "/Slike/Dogodki/PredavanjeSvetloba/slika4.jpg", text: "Slika 4: Predavatelj Dr.Matej Bernard Kobav", alt:"Predavatelj Dr.Matej Bernard Kobav" },
  { src: "/Slike/Dogodki/PredavanjeSvetloba/slika5.jpg", text: "Slika 5: Panorama dogodka", alt:"Panorama dogodka" },
  { src: "/Slike/Dogodki/PredavanjeSvetloba/slika6.jpg", text: "Slika 6: Zaključek predavanja", alt:"Zaključek predavanja" },
];

const PredavanjeSvetloba = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedText, setSelectedText] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleImageClick = (image, text, index) => {
    setSelectedImage(image);
    setSelectedText(text);
    setCurrentIndex(index);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setSelectedText(null);
    setCurrentIndex(null);
  };

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[nextIndex].src);
    setSelectedText(images[nextIndex].text);
    setCurrentIndex(nextIndex);
  };

  const handlePrev = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[prevIndex].src);
    setSelectedText(images[prevIndex].text);
    setCurrentIndex(prevIndex);
  };

  return (
    <div className="dogodkipodstranigallerycontainer">
      <h1 className="dogodkipodstranigallerytitle">Predavanje (Svetloba), Dr.Matej Bernard Kobav, 31.03.2025 </h1>
      <h3>( Galerija Slik )</h3>
      <div className="dogodkipodstranigallery">
        {images.map((image, index) => (
          <div className="dogodkipodstranigalleryitem" key={index}>
            <img
              src={image.src}
              alt={`Event ${index + 1}`}
              className="dogodkipodstranigalleryimage"
              loading="lazy"
              onClick={() => handleImageClick(image.src, image.text, index)}
            />
            <p className="dogodkipodstraniimagetext">{image.text}</p>
          </div>
        ))}
      </div>

      {selectedImage && (
        <div className="dogodkimodal" onClick={handleCloseModal}>
          <div className="dogodkimodalcontent" onClick={(e) => e.stopPropagation()}>
            <button className="dogodkiarrow-left" onClick={handlePrev}>
              &#8592;
            </button>
            <img
              src={selectedImage}
              alt="Full-size event"
              className="dogodkimodalimage"
            />
            <p className="dogodkimodaltext">{selectedText}</p>
            <button className="dogodkiclosebutton" onClick={handleCloseModal}>
              X
            </button>
            <button className="dogodkiarrow-right" onClick={handleNext}>
              &#8594;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PredavanjeSvetloba;